import React from 'react'
import { useTypewriter, Cursor } from "react-simple-typewriter";
import { FaFacebookF, FaInstagram, FaWhatsapp, FaReact } from "react-icons/fa";
import { SiTailwindcss, SiFigma, SiNextdotjs, SiNodedotjs, SiWordpress, SiMysql } from "react-icons/si";

const LeftBanner = () => {
    const [text] = useTypewriter({
      words: ["Full Stack Web Developer.",],
      loop: true,
      typeSpeed:100,
      deleteSpeed: 10,
      delaySpeed: 2000,
    });
  return (
    <div className="w-full lgl:w-1/2 flex flex-col gap-20">
      <div className="flex flex-col gap-5">
        <h4 className=" text-lg font-normal">WELCOME TO MY WORLD</h4>
        <h1 className="text-6xl font-bold text-white">
          Hi! I'm <span className="text-designColor capitalize">M.Saqib</span>
        </h1>
        <h2 className="text-4xl font-bold text-white">
          a <span>{text}</span>
          <Cursor
            cursorBlinking="false"
            cursorStyle="99%"
            cursorColor="#33ff6b"
          />
        </h2>
        <p className="text-base font-bodyFont leading-6 tracking-wide">
        I create scalable web applications with React.js, Node.js and API integration, focused on delivering efficient solutions and seamless user experiences.
        </p>
      </div>
      <div className="flex flex-col xl:flex-row gap-6 lgl:gap-0 justify-between">

      <div>
          <h2 className="text-base uppercase font-titleFont mb-4">
            BEST SKILL ON
          </h2>
          <div className="flex gap-4">
            <span className="bannerIcon">
              <FaReact />
            </span>
            <span className="bannerIcon">
              <SiNodedotjs />
            </span>
            <span className="bannerIcon">
              <SiWordpress />
            </span>
            <span className="bannerIcon">
              <SiMysql />
            </span>
          </div>
        </div>

        <div>
          <h2 className="text-base uppercase font-titleFont mb-4">
            Find me in
          </h2>
          <div className="flex gap-4">
          <a href="https://web.facebook.com/?_rdc=1&_rdr"className="bannerIcon">
          <FaFacebookF />
            </a>
           
          

            <a href="https://www.instagram.com/m.saqib977?igsh=dDlyaGNjMTU0bXRz"className="bannerIcon">
            <FaInstagram />
            </a>
          
            <a
            href="https://wa.me/923429765955"
            target="_blank"
            rel="noopener noreferrer"
            className="bannerIcon"
          >
            <FaWhatsapp />
          </a>
            
          </div>
        </div>
       
      </div>
    </div>
  );
}

export default LeftBanner