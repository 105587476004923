import React from 'react'
import { AiFillAppstore } from "react-icons/ai";
import { FaMobile, FaGlobe } from "react-icons/fa";
import { SiProgress, SiAntdesign, SiReact, SiNodedotjs, SiWordpress, SiAmazon, SiAwesomewm } from "react-icons/si";
import Title from '../layouts/Title';
import Card from './Card';

const Features = () => {
  return (
    <section
      id="features"
      className="w-full py-28 border-b-[1px] border-b-black"
    >
      <Title title="Services" des="What I Do" />
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-20">
        <Card
          icon={<SiReact/>}
          title="Reactjs"
          des="Crafting interactive, high-performance web interfaces."
         
        />
        <Card
          title="NodeApp"
          des="Building scalable, real-time server-side applications."
          icon={<SiNodedotjs/>}
        />
        <Card
          title="WordPress Development"
          des="Creating custom, user-friendly websites and blogs."
          icon={<SiWordpress />}
        />
        <Card
          title="Graphic Designing"
          des="Designing visually appealing graphics for brands."
          icon={<SiAntdesign/>}
        />
        <Card
          title="Digital Marketing"
          des="Driving targeted traffic for Digital marketing sales growth."
          icon={<SiAmazon />}
        />
        <Card
          title="Hosting Websites"
          des="Providing reliable, secure hosting for your websites."
          icon={<SiAwesomewm/>}
        />
      </div>
    </section>
  );
}

export default Features