import React from 'react';
import Title from '../layouts/Title';
import { projectOne, projectTwo, projectThree, wordpresswebsite, websitehosting, digitalmarketing } from "../../assets/index";
import ProjectsCard from './ProjectsCard';

const Projects = () => {
  return (
    <section
      id="projects"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Title
          title="VISIT MY PORTFOLIO AND KEEP YOUR FEEDBACK"
          des="My Projects"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
        <ProjectsCard
          title="Node Application"
          des="Building scalable, real-time server-side applications.For more details please click here."
          src={projectOne}
          link="https://mzcpk.com/" // website link
        />
        <ProjectsCard
          title="WordPress"
          des="Custom, user-friendly E-commerce websites and Landing page.For more details please click here."
          src={projectTwo}
          link="https://stonevalleyco.com/"
        />
        <ProjectsCard
          title="React App"
          des="Crafting interactive, high-performance web interfaces."
          src={projectThree}
          link="https://www.geeksforgeeks.org/"
        />
        <ProjectsCard
          title="E-Commerce"
          des="Custom, user-friendly websites and blogs.For more details please click here."
          src={wordpresswebsite}
          link="https://www.georgiaexpo.com/" 
        />
        <ProjectsCard
          title="Digital Marketing"
          des="Driving targeted traffic for Digital marketing sales growth."
          src={digitalmarketing}
          link="https://www.mymobelity.be/" 
        />
        <ProjectsCard
          title="Website Hosting"
          des="Providing reliable, secure share hosting for your App & websites.For more details please click here."
          src={websitehosting}
          link="https://my.symbolhost.com/aff.php?aff=631"
        />
      </div>
    </section>
  );
};

export default Projects;