import React, { useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import {logo} from "../../assets/index"

const PackagesSection = () => {
  const [showPopup, setShowPopup] = useState(false);

  const packageData = [
    {
      title: 'Basic Package',
      price: '$80',
      features: [
        'Static website development',
        'HTML, CSS, and JavaScript integration',
        'Mobile responsiveness',
        'Basic SEO setup',
        'Contact form',
        'Free SSL ',
      ],
      buttonText: 'Buy Now',
    },
    {
      title: 'Standard Package',
      price: '$150',
      features: [
        'Dynamic website development',
        'Full stack (React.js & Node.js)',
        'Database integration',
        'User authentication',
        'Contact form',
        'Advanced SEO setup',
        'Mobile responsiveness',
        'Basic API integration',
      ],
      buttonText: 'Buy Now',
    },
    {
      title: 'Premium Package',
      price: '$350',
      features: [
        'Full-scale dynamic web application ',
        'Complete full-stack',
        'Database setup and management',
        'Advanced user authentication',
        'Payment gateway integration',
        'API integration',
        'Security optimizations',
        'Performance optimizations',
        'E-commerce functionality',
        'Ongoing maintenance and support',
      ],
      buttonText: 'Buy Now',
    },
  ];

  const handleBuyNowClick = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <section id="packages" className="w-full py-20 border-b-[1px] border-b-black">
      <div className="flex justify-center items-center text-center mb-8">
        <h1 className="text-5xl font-bold capitalize mb-5">My Packages</h1>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14 justify-center items-center text-center">
        {packageData.map((pkg, index) => (
          <div
            key={index}
            className="w-full p-4 xl:px-12 h-auto xl:py-10 rounded-lg shadow-shadowOne flex flex-col bg-gradient-to-r from-bodyColor to-[#202327] group hover:bg-gradient-to-b hover:from-gray-900 hover:gray-900 transition-colors duration-1000"
          >
            <div className="w-full mt-5 flex flex-col gap-6 justify-center items-center text-center">
              <h2 className="text-2xl capitalize text-designColor font-semibold mb-2">{pkg.title}</h2>
              <p className="text-lg font-medium text-white mb-4">{pkg.price}</p>
              <ul className="mb-6">
                {pkg.features.map((feature, i) => (
                  <li key={i} className="flex items-center mb-2">
                    <FaCheck className="text-designColor-500 mr-2" /> {feature}
                  </li>
                ))}
              </ul>
              <button
                onClick={handleBuyNowClick}
                className="bg-black text-white py-2 px-4 rounded-lg hover:bg-gray-700 transition-colors duration-300"
              >
                {pkg.buttonText}
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Popup Modal */}
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-gray-900 p-4 rounded-lg max-w-md w-full">
            {/* Popup Header */}
            <div className="flex justify-between items-center mb-4">
            <img src={logo} className=" mt-0 w-36" alt="logo"/>
              <button onClick={handleClosePopup} className="text-red-700 text-2xl">&times;</button>
            </div>

            {/* Payment Details */}
            <div className="mb-4">
              <h2 className="text-xl font-bold mb-2">Payment Details</h2>
              <p><strong>EasyPaisa:</strong> 0342-9765955</p>
              <p><strong>Meezan Bank:</strong> Account No: 270101107582706</p>
              <p><strong>Account Title:</strong> Muhammad Saqib</p>
            </div>

          </div>
        </div>
      )}
    </section>
  );
};

export default PackagesSection;
