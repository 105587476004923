import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

const WhatsappIcon = () => {
  return (
    <a
      href="https://wa.me/923429765955" // Replace with your WhatsApp number
      target="_blank"
      rel="noopener noreferrer"
      className="fixed bottom-4 right-4 z-50 bg-green-500 p-2 rounded-full shadow-lg hover:scale-110 transition-transform"
    >
      <FaWhatsapp size={32} className="text-white" />
    </a>
  );
};

export default WhatsappIcon;
