import React, { useState, useEffect } from 'react';
import { bannerImg } from "../../assets/index";

const RightBanner = () => {
  const [showBanner, setShowBanner] = useState(window.innerWidth > 1020);

  useEffect(() => {
    const handleResize = () => {
      setShowBanner(window.innerWidth > 1020);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    showBanner ? (
      <div className="w-full lgl:w-1/2 flex justify-center items-center relative ml-36">
        <img
          className="w-[300px] h-[400px] lgl:w-[500px] lgl:h-[510px] z-10"
          src={bannerImg}
          alt="bannerImg"
          style={{
            boxShadow: '0px 0px 20px 5px rgba(255, 0, 0, 0.6)', // Red glow effect
            transition: 'box-shadow 0.3s ease-in-out' // Smooth transition effect
            
          }}
        />
        <div className="absolute bottom-0 w-[350px] h-[300px] lgl:w-[500px] lgl:h-[500px] flex justify-center items-center"></div>
      </div>
    ) : null
  );
}

export default RightBanner;
